import React, { ChangeEvent, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { R_subscribe } from "../components/events"
import { localize } from "../components/localization"
import { sign_in } from "../components/auth"
import { is_nothing, to_2_sym } from "../components/utils"
import { Tooltip } from 'react-tooltip';

export const DotaPlusSubscribe = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const authorized = R_subscribe("authorized")
	const extend = authorized && authorized.dotaPlusExpireDate && authorized.dotaPlusExpireDate > Date.now()
	let expireStatus: string | undefined = undefined

	if (authorized && authorized.dotaPlusExpireDate) {

		if (authorized.dotaPlusExpireDate < 0) {
			expireStatus = '';
		}
		else {
			const date = new Date(authorized.dotaPlusExpireDate)
			expireStatus = localize(
				authorized.dotaPlusExpireDate < Date.now() ? "dota_plus_expired_date" : "dota_plus_expire_date"
			).replace(
				"%date%",
				`${to_2_sym(date.getDate())}.${to_2_sym(date.getMonth() + 1)}.${date.getFullYear().toString().slice(2, 4)}`
			)
		}
	}

	return (
		<div>
			{expireStatus ?
				<div
					className="mx-auto w-[30rem] text-center text-2xl text-[#ddd] font-bold text-shadow"
					dangerouslySetInnerHTML={{ __html: expireStatus }}
				/>
				: undefined}
			<button
				className={`
					mx-auto mt-4 h-12 p-9 bg-gradient-to-r from-[#d0af02] to-[#ddd441] shadow-gold
					brightness-100 hover:brightness-125 transition transition-all ease-linear duration-100
					flex items-center justify-center text-3xl sm:text-4xl text-white font-bold text-shadow-xl
				` }
				onClick={() => {
					if (authorized && authorized.authorized) {
						searchParams.set("popup", "dota_plus")
						setSearchParams(searchParams)
					} else {
						sign_in()
					}
				}}
			>
				{localize(extend ? "dota_plus_subscribe_extend" : "dota_plus_subscribe")}
			</button>
		</div>
	)
}

export const Loading: React.FC = () =>
	<div className="text-[#ccc] text-xl">Loading...</div>

export const BigButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = props =>
	<button className={`bg-gray-900 p-4 ${props.className || ""}`}>{props.children}</button>

export const SizedImage: React.FC<{
	src?: string,
	className?: string,
	imgClassName?: string,
	alt?: string,
	dataTooltipHtml?: string
	dataTooltipId?: string
}> = props =>
		<div className={props.className || ""} data-tooltip-id={props.dataTooltipId || ''} data-tooltip-html={props.dataTooltipHtml || ''}>
			<img className={`h-full ${props.imgClassName || ""}`} src={props.src} alt={props.alt || ""} />
		</div>



export const DropDown: React.FC<{
	isOpen: boolean
	children?: React.ReactNode
	className?: string
	openClassName?: string
	closeClassName?: string
}> = props =>
		<div className={`heroes-window-time absolute z-[1000] flex flex-col overflow-y-auto bg-black ${props.className || ""} ${props.isOpen ?
			is_nothing(props.openClassName) ? "max-h-64 heroes-window-time-border" : props.openClassName
			:
			is_nothing(props.closeClassName) ? "max-h-0" : props.closeClassName
			}`}>{props.children}</div>

export const HeroImage_leaderboard: React.FC<{ heroName: THeroName | undefined }> = props =>
	<SizedImage className="h-12" src={`/images/heroes/${props.heroName}.png`} />

export const HeroImageTable = (props: { heroName: string }) =>
	<SizedImage
		className="rounded-xl h-10 sm:h-16 overflow-hidden shadow-sm shadow-black"
		imgClassName="w-16 sm:w-24"
		src={`images/heroes/${props.heroName}.png`}
	/>

export const TalentImageTable = (props: { talentName: string }) =>
	<div className={`
		rounded-xl
		bg-gradient-to-b
		from-orange-400
		via-orange-500
		to-orange-700
		flex
		items-center
		h-[2.1rem] 
		w-[2.1rem]
		sm:h-[3.2rem] 
		sm:w-[3.2rem]
		shadow-sm
		shadow-black
		relative
		right-[1rem]
	` }>
		<SizedImage
			className="rounded-xl h-8 w-8 sm:h-12 sm:w-12 overflow-hidden mx-auto"
			src={`images/talents/${props.talentName}.png`}
		/>
	</div>


export const Place = (props: {
	place: string
	rate: number
	color: string
	value?: string | number
}) =>
	<div className="w-4 h-6 sm:h-10">
		<div className="flex-1 w-[90%] h-6 sm:h-10 flex flex-col">
			<div className="flex-1" />
			<div style={{ height: `${props.rate * 100}%` }} className={`
				w-full
				bg-gradient-to-t
				${props.color}
				bottom-0
			` } />
		</div>
		<div className="text-xs text-center">
			{props.place}
		</div>
	</div>

export const Places = (props: {
	places: { [k: string]: number }
}) => {
	const colors: { [k: string | number]: string } = {
		"1": "from-[#00af00] via-[#00de00] to-[#00de00]",
		"2": "from-[#42af00] via-[#63de00] to-[#63de00]",
		"3": "from-[#96af00] via-[#afde00] to-[#afde00]",
		"4": "from-[#af9600] via-[#deaf00] to-[#deaf00]",
		"5": "from-[#af4200] via-[#de6300] to-[#de6300]",
		"6": "from-[#af0000] via-[#de0000] to-[#de0000]",
	}

	return (
		<div className="w-full flex flex-col items-center">
			<div className="flex flex-row">
				{Object
					.keys(colors)
					.map((k, i) => <Place key={i} place={k} color={colors[k]} rate={props.places[k] || 0} />)
				}
			</div>
		</div>
	)
}

export const FlowBlock: React.FC<{ type: string }> = props =>
	<div
		style={{ backgroundImage: `url( 'images/dota_plus/flow/${props.type}.png' )` }}
		className={`z-[-1] h-9 bg-[length:100%] bg-cover`}
	/>

export const SortableTable: React.FC<{
	columns: {
		title: string
		sortMethod?: "alph" | "mtl" | "ltm" | "unq"
		sortUnique?: (a: any, b: any) => number
		valueFunc?: (x: any) => JSX.Element | string | number | undefined
		stretch?: boolean
	}[]
	rows: any[][]
	tableClassName?: string
	headClassName?: string
	headRowClassName?: string
	headCellClassName?: string | ((sorted: boolean) => string)
	bodyClassName?: string
	bodyRowClassName?: string
	bodyCellClassName?: string
	bodyRowStyle?: React.CSSProperties
}> = props => {
	const [sorting, setSorting] = React.useState({
		mode: false,
		column: 0
	})

	const sortMethod = props.columns[sorting.column].sortMethod
	const sortUnique = props.columns[sorting.column].sortUnique
	let rows: any[][] = props.rows

	if (sortMethod) {
		rows = (rows as Array<number | string>[]).sort((a, b) => {
			const va = a[sorting.column]
			const vb = b[sorting.column]

			if (sortMethod === "alph") {
				return va.toString().localeCompare(vb.toString())
			} else if (sortMethod === "mtl") {
				return Number(va) > Number(vb) ? -1 : 1
			} else if (sortMethod === "ltm") {
				return Number(va) > Number(vb) ? 1 : -1
			} else if (sortMethod === "unq" && sortUnique) {
				return sortUnique(va, vb)
			}

			return 0
		})
	}

	const valueRows = rows.map(row => row.map((v, i) => {
		const column = props.columns[i]

		if (column && column.valueFunc) {
			return column.valueFunc(v)
		}

		return v
	}))

	if (sorting.mode) {
		valueRows.reverse()
	}

	return (
		<table className={props.tableClassName}>
			<thead className={props.headClassName}>
				<tr className={props.headRowClassName}>
					{
						props.columns.map((x, i) => (
							<th
								key={i}
								className={typeof props.headCellClassName === "function" ? props.headCellClassName(sorting.column === i) : props.headCellClassName}
								onClick={x.sortMethod ? () => {
									if (sorting.column === i) {
										setSorting({
											mode: !sorting.mode,
											column: i
										})
									} else {
										setSorting({
											mode: false,
											column: i
										})
									}
								} : undefined}
							>
								{x.title}
							</th>
						))
					}
				</tr>
			</thead>
			<tbody className={props.bodyClassName}>
				{valueRows.map((row, ri) => (
					<tr key={ri} style={props.bodyRowStyle} className={props.bodyRowClassName}>
						{row.map((x, i) => (
							<td className={props.bodyCellClassName} key={i}>{x}</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	)
}

export const SwitchWithValuesBtn: React.FC<{
	isOn: boolean, handleToggle: any, classNames?: string
	leftValue: string, rigthValue: string
}> = props => {
	const isOn = props.isOn;

	return (
		<label className={`switch ${props.classNames}`}>
			<input
				checked={isOn}
				onChange={props.handleToggle}
				className="switch-checkbox"
				type="checkbox"
			/>
			<div className="switch-button" />
			<div className="switch-labels">
				<span style={{ opacity: `${isOn ? 0.2 : 1}` }}
				>
					{props.leftValue}
				</span>
				<span style={{ opacity: `${isOn ? 1 : 0.2}` }}
				>
					{props.rigthValue}
				</span>
			</div>
		</label>
	);
}