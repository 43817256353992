import React from "react"
import { useSearchParams, NavLink } from "react-router-dom"
import { sign_in } from "../components/auth"
import { localize } from "../components/localization"
import { to_2_sym, background_style } from "../components/utils"
import { R_subscribe, set_state } from "../components/events"
import { FlowBlock, SizedImage } from "./samples"

const SubButton: React.FC<{ unsubscribe?: boolean }> = props => {
	const [searchParams, setSearchParams] = useSearchParams()
	const authorized = R_subscribe("authorized")
	const extend = authorized && authorized.dotaPlusExpireDate && authorized.dotaPlusExpireDate > Date.now()
	let expireStatus: string | undefined = undefined

	if (authorized && authorized.dotaPlusExpireDate) {

		if (authorized.dotaPlusExpireDate < 0) {
			expireStatus = '';
		}
		else {
			const date = new Date(authorized.dotaPlusExpireDate)
			expireStatus = localize(
				authorized.dotaPlusExpireDate < Date.now() ? "dota_plus_expired_date" : "dota_plus_expire_date"
			).replace(
				"%date%",
				`${to_2_sym(date.getDate())}.${to_2_sym(date.getMonth() + 1)}.${date.getFullYear().toString().slice(2, 4)}`
			)
		}
	}

	return (
		<div className="flex flex-col">
			{expireStatus ?
				<div
					className="dota-plus-small mx-auto mt-10 xl:mt-0 xl:w-[30rem] text-center text-2xl text-[#ddd] font-bold text-shadow"
					dangerouslySetInnerHTML={{ __html: expireStatus }}
				/>
				: undefined}
			<div className="mx-auto flex flex-col items-end">
				<button
					className={`	
						hidden xl:flex
						dota-plus-button-text mx-auto mt-4 h-12 p-9 bg-gradient-to-r from-[#e67c00] to-[#fff442] shadow-gold
						brightness-100 hover:brightness-125 transition transition-all ease-linear duration-100
						flex items-center justify-center text-white font-bold text-shadow-xl
					` }
					onClick={() => {
						if (authorized && authorized.authorized) {
							searchParams.set("popup", "dota_plus")
							setSearchParams(searchParams)
						} else {
							sign_in()
						}
					}}
				>
					{localize(extend ? "dota_plus_subscribe_extend" : "dota_plus_subscribe")}
				</button>
				<button
					className={`	
						xl:hidden
						dota-plus-button-text-xs mx-auto mt-4 h-12 p-9 bg-gradient-to-r from-[#e67c00] to-[#fff442] shadow-gold
						brightness-100 hover:brightness-125 transition transition-all ease-linear duration-100
						flex items-center justify-center text-white font-bold text-shadow-xl
					` }
					onClick={() => {
						if (authorized && authorized.authorized) {
							searchParams.set("popup", "dota_plus")
							setSearchParams(searchParams)
						} else {
							sign_in()
						}
					}}
				>
					{localize(extend ? "dota_plus_subscribe_extend" : "dota_plus_subscribe")}
				</button>
				{props.unsubscribe && authorized && authorized.authorized && authorized.isSubscribed ?
					<button
						className="text-[#ddd] text-xl font-semibold hover:text-white text-shadow-xl"
						onClick={async () => {
							set_state("authorized", Object.assign({}, authorized, { isSubscribed: false }))

							await fetch("/api/payment/unsubscribe", { method: "POST" })
						}}
					>{localize("unsubscribe")}</button>
					: undefined}
			</div>
		</div>
	)
}

const MobileDescBlock: React.FC<{
	type: string
	right?: boolean
}> = props =>
		<div className={`
		bg-cover shadow-custom
		border-t border-b border-solid border-[#ccc]
		flex items-center
		${props.right ? "" : "flex-row-reverse"}
	` }>
			<SizedImage
				className="hidden sm:block z-[10] brightness-[0.4] relative"
				src={`/images/dota_plus/bg/${props.type}.png`}

			/>
			<SizedImage
				className="sm:hidden z-[10] brightness-[0.4] relative"
				imgClassName="w-[100%] h-full object-cover object-top"
				src={`/images/dota_plus/bg/mobile${props.type}.png`}
			/>
			<div className={`hidden sm:block
			w-[28rem] absolute p-2 text-[#fff] font-bold text-shadow z-[10] text-[18px]
			${props.right ? "mr-7 text-right" : "ml-7 "}
		` } dangerouslySetInnerHTML={{ __html: localize(`dota_plus_desc_${props.type}`) }} />

			<div className={`sm:hidden
			absolute p-2 text-[#fff] font-bold text-shadow z-[10] text-[18px]
		` } dangerouslySetInnerHTML={{ __html: localize(`dota_plus_desc_${props.type}`) }} />
		</div>

const DescBlock: React.FC<{
	type: string
	right?: boolean
}> = props =>
		<div style={{ backgroundImage: `url( 'images/dota_plus/bg/${props.type}.png' )` }} className={`
		z-[10] w-full h-[23rem] bg-[length:100%_100%] bg-cover shadow-custom
		border-t border-b border-solid border-[#ccc]
		flex items-center
		${props.right ? "" : "flex-row-reverse"}
	` }>
			<div className="min-w-64 flex-1" />
			<div className={`
			w-[32rem] text-[#ddd] font-bold text-shadow
			${props.right ? "mr-7 text-right text-block-right" : "ml-7 text-block-left"}
		` } dangerouslySetInnerHTML={{ __html: localize(`dota_plus_desc_${props.type}`) }} />
		</div>


export const DotaPlus: React.FC = () => {
	return <div>
		<DotaPlusMobile />
		<DotaPlusDesctop />
	</div>
}

export const DotaPlusMobile: React.FC = () => {
	return <div className="xl:hidden">
		<div style={background_style("images/dota_plus/flow/1.png")} className="bg-[length:100%_100%]">
			<div className="mx-2">
				<div className="flex flex-col ">
					<div className="flex">
						<div className="mx-auto mt-8 shadow-gold bg-[length:100%_100%]">
							<SizedImage
								src="/images/dota_plus/battle_pass.png"
							/>
						</div>
					</div>
					<div className="min-w-[50%] flex flex-col">
						<div className={`dota-plus-header mx-auto mt-8 text-[#fedf84] text-5xl sm:text-6xl font-bold text-shadow`} >
							{localize("dota_plus_title")}
						</div>
						<div className={`dota-plus-small mx-auto text-[#ddd] text-2xl font-bold text-shadow`} >
							{localize("dota_plus_title_mini")}
						</div>
						<div className="flex-1" />
						<SubButton unsubscribe={true} />
					</div>
				</div>
				<div className="flex">
					<div className={`dota-plus-small mx-auto my-12 text-[#fedf84] text-4xl sm:text-6xl font-bold text-shadow`} >
						{localize("dota_plus_what_gives")}
					</div>
				</div>
			</div>
		</div>
		<MobileDescBlock type="1" right={true} />
		<FlowBlock type="2" />
		<MobileDescBlock type="2" />
		<FlowBlock type="3" />
		<MobileDescBlock type="3" right={true} />
		<FlowBlock type="4" />
		<MobileDescBlock type="4" />
		<FlowBlock type="3" />
		<MobileDescBlock type="5" right={true} />
		<FlowBlock type="2" />
		<MobileDescBlock type="6" />
		<FlowBlock type="3" />
		<MobileDescBlock type="7" right={true} />
		<FlowBlock type="3" />
		<MobileDescBlock type="8" />
		<FlowBlock type="3" />
		<MobileDescBlock type="9" right={true} />
		<div style={background_style("images/dota_plus/flow/1.png")} className="p-2 pt-10 bg-[length:100%_100%] shadow-custom">
			<div className="sm:flex sm:flex-row sm:justify-between">
				<div className="flex sm:items-center font-semibold text-shadow">
					<div
						className="text-[#ddd] text-xl"
						dangerouslySetInnerHTML={{ __html: localize("contacts") }}
					/>
				</div>
				<div className="mt-5 flex flex-col text-center items-start">
					<div className="flex items-center">
						<SizedImage className="m-1 w-12" src="/images/payment/mastercard.png" />
						<SizedImage className="m-1 w-12" src="/images/payment/visa.png" />
						<SizedImage className="m-1 w-12" src="/images/payment/mir.png" />
						<SizedImage className="m-1 w-12" src="/images/payment/jcb.png" />
					</div>
					<a className="text-[#ddd] z-[10] text-shadow text-xl hover:underline" href="/docs/privacy.pdf" target="__blank">
						{localize("privacy")}
					</a>
				</div>
			</div>
			<br />
		</div>
	</div>
}

export const DotaPlusDesctop: React.FC = () => {
	return (
		<div className="hidden xl:block w-full flex flex-col">
			<div style={background_style("images/dota_plus/flow/1.png")} className="bg-[length:100%_100%]">
				<div className="flex">
					<div className="flex-1">
						<div
							style={background_style("images/dota_plus/battle_pass.png")}
							className="mx-auto mt-8 w-[30rem] h-[21rem] shadow-gold bg-[length:100%_100%]"
						/>
					</div>
					<div className="min-w-[50%] flex flex-col">
						<div className={`dota-plus-header mx-auto mt-8 text-[#fedf84] text-6xl font-bold text-shadow`} >
							{localize("dota_plus_title")}
						</div>
						<div className={`dota-plus-small mx-auto text-[#ddd] text-3xl font-bold text-shadow`} >
							{localize("dota_plus_title_mini")}
						</div>
						<div className="flex-1" />
						<SubButton unsubscribe={true} />
					</div>
				</div>
				<div className="flex">
					<div className={`dota-plus-small mx-auto my-12 text-[#fedf84] text-6xl font-bold text-shadow`} >
						{localize("dota_plus_what_gives")}
					</div>
				</div>
			</div>
			<DescBlock type="1" />
			<FlowBlock type="2" />
			<DescBlock type="2" right={true} />
			<FlowBlock type="3" />
			<DescBlock type="3" />
			<FlowBlock type="4" />
			<DescBlock type="4" right={true} />
			<FlowBlock type="3" />
			<DescBlock type="5" />
			<FlowBlock type="2" />
			<DescBlock type="6" right={true} />
			<FlowBlock type="3" />
			<DescBlock type="7" />
			<FlowBlock type="3" />
			<DescBlock type="8" right={true} />
			<FlowBlock type="3" />
			<DescBlock type="9" />
			<div style={background_style("images/dota_plus/flow/1.png")} className="pt-12 bg-[length:100%_100%] shadow-custom">
				<SubButton />
				<div className="z-[-20] mt-12 mb-6 mx-auto p-4 flex items-center font-semibold text-shadow">
					<div
						className="text-[#ddd] text-xl"
						dangerouslySetInnerHTML={{ __html: localize("contacts") }}
					/>
					<div className="flex-1" />
					<div className="flex flex-col items-end">
						<div className="flex items-center">
							<SizedImage className="m-1 w-16" src="/images/payment/mastercard.png" />
							<SizedImage className="m-1 w-16" src="/images/payment/visa.png" />
							<SizedImage className="m-1 w-16" src="/images/payment/mir.png" />
							<SizedImage className="m-1 w-16" src="/images/payment/jcb.png" />
						</div>
						<a className="text-[#ddd] z-[10] text-shadow text-xl hover:underline" href="/docs/privacy.pdf" target="__blank">
							{localize("privacy")}
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}